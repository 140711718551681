import React, { useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "../.././common/CustomInput";

import { useDispatch } from "react-redux";
import { EDITSETTING } from "../../../redux/actions/SettingActions";
import ImagesInput from "../../common/ImagesInput";
import { useState } from "react";
import { object } from "yup";
import PropTypes from "prop-types";

const ProfileModal = ({ openModal, onCloseModal, dataList }) => {

  const [image, setImage] = useState(null);

  const dispatch = useDispatch();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = object()
    .shape({
      storeEmail: yup.string().email().required("email is required"),
      storeName: yup.string().max(40).required(),
      address: yup.string().max(250).required(),
      tax_label: yup
      .string()
      .min(3, "Tax label must be at least 3 characters")
      .max(30, "Tax label must not exceed 30 characters")
      .matches(/^[A-Za-z()%]+$/, "Tax label can only contain alphabets and '%', '()'")
      .required("Tax label is required"),
      mobile: yup.string().matches(phoneRegExp, "Phone number is not valid"),
      gst: yup
        .number("gst number is required")
        .positive("number can't start with a minus")
        .required("gst is required"),
      maximumOrderQuantity: yup
        .string()
        .min(0,"Please enter a valid positive integer")
        .required("Maximum order quantity is required"),
      deliveryCharge: yup
        .string()
        .matches(/[0-9]+/, "not valid")
        .required("Delivery charge required"),
      image: yup
        .mixed()
        .test(
          "resolution",
          "Image resolution should be 256px * 256px",
          async (file) => {
            if (file) {
              return new Promise((resolve) => {
                const img = new Image();
                img.src = URL.createObjectURL(file);
                img.onload = () => {
                  if (img.naturalWidth === 256 && img.naturalHeight === 256) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                };
                img.onerror = () => {
                  resolve(false);
                };
              });
            }
            return true; // Skip resolution check if file is not provided
          }
        )
        .test("fileSize", "The file is too large", async (file) => {
          if (file) {
            // Check file size
            return file.size <= 2000000;
          }
          return true; // Skip size check if file is not provided
        }),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onImgLoad = ({ target: img }) => {
    if (img.naturalWidth === 256 && img.naturalHeight === 256) {
      setValue("image", image);
      setError("image", { type: "custom", message: null });
    } else {
      setValue("image", null);
      setError("image", {
        type: "custom",
        message: "Image resolution should be 256px * 256px",
      });
    }
  };

  const [itemSwitch, setItemSwitch] = useState(false);

  const onsubmit = (data) => {
    const formData = new FormData();
    formData.append("delivery_slot", itemSwitch === true ? 1 : 0);
    formData.append("id", dataList?._id);
    formData.append("address", data?.address);
    formData.append("storename", data?.storeName);
    formData.append("storeemail", data?.storeEmail);
    formData.append("mobile", data?.mobile);
    formData.append("maximum_order_quantity", data?.maximumOrderQuantity);
    formData.append("delivery_charge", data?.deliveryCharge);
    formData.append("tax_label", data?.tax_label);
    formData.append("gst", data?.gst);
    formData.append("primary_color", data?.primaryColor);
    formData.append("secondary_color", data?.secondaryColor);
    formData.append("tertiary_color", data?.tertiaryColor);
    formData.append(
      "header_color",
      data?.headerColor ? data?.headerColor : null
    );
    formData.append(
      "footer_color",
      data?.footerColor ? data?.footerColor : null
    );
    formData.append(
      "footer_text_color",
      data?.footerTextColor ? data?.footerTextColor : null
    );
    formData.append("body_color", data?.bodyColor ? data?.bodyColor : null);
    if (data?.image) {
      formData.append("logo", data?.image);
    }

    dispatch(EDITSETTING(formData));
  };

  useEffect(() => {
    if (dataList) {
      setValue("storeName", dataList?.storename);
      setValue("storeEmail", dataList?.storeemail);
      setValue("address", dataList?.address);
      setValue("mobile", dataList?.mobile);
      setValue("gst", dataList?.gst);
      setValue("tax_label", dataList?.tax_label);
      setValue("maximumOrderQuantity", dataList?.maximum_order_quantity);
      setValue("deliveryCharge", dataList?.delivery_charge);
      setValue("primaryColor", dataList?.primary_color);
      setValue("tertiaryColor", dataList?.tertiary_color);
      setValue("secondaryColor", dataList?.secondary_color);
      setValue("headerColor", dataList?.header_color);
      setValue("footerColor", dataList?.footer_color);
      setValue("footerTextColor", dataList?.footer_text_color);
      setValue("bodyColor", dataList?.body_color);
      setItemSwitch(dataList?.delivery_slot === 1 ? true : false);
    }
  }, [dataList]);

  useEffect(() => {
    if (!openModal) {
      reset(); // Reset the form state when the modal is closed
      setImage(null); // Reset the image state
      clearErrors(); // Clear any form errors
    }
  }, [openModal, reset, clearErrors]);

  return (
    <Modal isOpen={openModal} toggle={onCloseModal}>
      <ModalHeader toggle={onCloseModal}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Edit Profile
        </h5>
        {image && (
          <div
            style={{
              height: 1,
              opacity: 0,
              width: 1,
              overflow: "hidden",
              position: "absolute",
            }}
          >
            <img onLoad={onImgLoad} src={URL.createObjectURL(image)} />
          </div>
        )}
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row xl="2">
            <CustomInput
              fieldLabel="Store Name"
              control={control}
              error={errors.storeName}
              fieldName="storeName"
            />
            <CustomInput
              fieldLabel="Store Email"
              control={control}
              error={errors.storeEmail}
              fieldName="storeEmail"
            />
          </Row>

          <Row xl="2">
            <CustomInput
              fieldLabel="Address"
              control={control}
              error={errors.address}
              fieldName="address"
            />
            <CustomInput
              fieldLabel="Mobile"
              control={control}
              error={errors.mobile}
              fieldName="mobile"
              type={"number"}
            />
          </Row>

          <CustomInput
            fieldLabel="Maximum Order Quantity"
            control={control}
            error={errors.maximumOrderQuantity}
            fieldName="maximumOrderQuantity"
            type={"number"}
          />

          <CustomInput
            fieldLabel="Delivery Charge"
            control={control}
            error={errors.deliveryCharge}
            fieldName="deliveryCharge"
            type={"number"}
          />

            <CustomInput
            fieldLabel="Tax Label"
            control={control}
            error={errors.tax_label}
            fieldName="tax_label"
          />

          <CustomInput
            fieldLabel="Tax (%)"
            control={control}
            error={errors.gst}
            fieldName="gst"
            type={"number"}
          />
          <Row xl="3">
            <CustomInput
              fieldLabel="Primary Color"
              control={control}
              error={errors.primaryColor}
              fieldName="primaryColor"
              type={"color"}
            />
            <CustomInput
              fieldLabel="Secondary Color"
              control={control}
              error={errors.secondaryColor}
              fieldName="secondaryColor"
              type={"color"}
            />
            <CustomInput
              fieldLabel="Tertiary Color"
              control={control}
              error={errors.tertiaryColor}
              fieldName="tertiaryColor"
              type={"color"}
            />
          </Row>
          <Row xl="3">
            <CustomInput
              fieldLabel="Header Color"
              control={control}
              error={errors.headerColor}
              fieldName="headerColor"
              type={"color"}
            />
            <CustomInput
              fieldLabel="Body Color"
              control={control}
              error={errors.bodyColor}
              fieldName="bodyColor"
              type={"color"}
            />
            <CustomInput
              fieldLabel="Footer Text Color"
              control={control}
              error={errors.footerTextColor}
              fieldName="footerTextColor"
              type={"color"}
            />
            <CustomInput
              fieldLabel="Footer  Color"
              control={control}
              error={errors.footerColor}
              fieldName="footerColor"
              type={"color"}
            />
          </Row>
          <div>
            <img
              src={`${dataList?.image}${dataList?.logo}`}
              style={{ width: "80px", height: "80px" }}
            ></img>
          </div>

          <ImagesInput
            fieldLabel="Image : 256px * 256px"
            control={control}
            error={errors.image}
            fieldName="image"
            type="file"
            onChangeValue={(file) => {
              setImage(file);
            }}
          />

          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <h5 style={{ marginTop: 10, fontSize: 12 }}>
              Enable delivery slot
            </h5>
            <Input
              checked={itemSwitch}
              onChange={(e) => setItemSwitch(e.target.checked)}
              type="checkbox"
            />
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={handleSubmit(onsubmit)}>
          Save
        </Button>
        <Button type="button" color="secondary" onClick={onCloseModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ProfileModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  dataList: PropTypes.array,
};

export default ProfileModal;
