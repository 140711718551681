import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import data from '../unit/unitdata';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import SelectInput from '../../common/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { CreateCategory, CreateSubcategory, EditsubCategory, GetCategory, UpdateCategory } from '../../../redux/actions/productActions';
import ImagesInput from '../../common/ImagesInput';
import { RESET_ERROR } from "../../../redux/constants/userConstants";
const AddSubCategory = ({ openModal, onCloseModal, data, setActiveData, title }) => {
    const { getcatogrylist, newsubcategory } = useSelector(state => state.product)

    const [catArray, setCatArray] = useState([])

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

   
    




    const schema = yup.object({
        name: yup.string()
        .required("Sub Category is required")
        // .max(40, "Category must be at most 40 characters")
        .matches(/^[A-Za-z\s]+$/, "Sub category must contain only alphabets").typeError("Sub Category is required"),
        description: yup.string()
        .required("Description is required")
        .matches(/^[A-Za-z\s]+$/, "Description must contain only alphabets")
        .max(100, "Description must be at most 40 characters")
        .typeError("Description is required"),
        tax: yup.string()
        .required("Tax is required")
        .matches(/^\d+$/, "Tax must contain only numbers")
        .typeError("T is required"),
        category_id: yup.object().typeError("Category is required")

    }).required();

    useEffect(() => {
      reset()
    
      return () => {
        reset()
      }
    }, [])
    


    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            description: '',
            tax: '',
            category_id: ''
        }
    });

    useEffect(() => {
        if(getcatogrylist){
            let cate = getcatogrylist?.map(cat => {
                return {
                    value: cat._id,
                    label: cat?.name
                }
            })

            setCatArray(cate)
        }

        if(newsubcategory){
            reset();
        }
    }, [getcatogrylist, newsubcategory])

    useEffect(() => {
        dispatch(GetCategory())
    }, [])

    useEffect(() => {
        if (data) {
            let cat = catArray.find(ca => ca.value === data?.category_id)
            setValue("name", data?.name)
            setValue("description", data?.description)
            setValue("tax", data?.tax)
            setValue("category_id", cat)
        } else {
            reset();
        }
    }, [data])

    const submitform = (datas) => {
        setLoading(true)
        console.log("submit form")
        if (data) {
            let input = {
                id: data?._id,
                category_id: datas?.category_id?.value,
                status: data?.status,
                name: datas?.name,
                description: datas?.description,
                tax: datas?.tax

            }

            console.log({input})
            dispatch(EditsubCategory(input)).finally(() => setLoading(false));
            // setValue("name", "")
            // setValue("description", "")
            // setValue("tax", "")
            // setValue("category_id", "")
            // setActiveData(null)
        } else {
            datas.category_id = datas?.category_id?.value
            //console.log({datas})
            dispatch(CreateSubcategory(datas)).finally(() => setLoading(false));
        }


    }
    const clearclose = () => {
        setValue("name", "")
        setValue("description", "")
        setValue("tax", "")
        setValue("category_id", "")
        setActiveData(null)
        onCloseModal()
        reset()

    }

    return (
        <Modal isOpen={openModal} toggle={clearclose}>
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    {title}
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <SelectInput fieldLabel='Category List'

                        control={control}
                        error={errors.category_id}
                        fieldName='category_id'
                        options={catArray}
                    />

                    <CustomInput
                        fieldLabel='Sub Category Name :'
                        control={control}
                        error={errors.name}
                        fieldName='name'
                    />
                    <CustomInput
                        fieldLabel='Description :'
                        control={control}
                        error={errors.description}
                        fieldName='description'
                    />
                    <CustomInput
                        fieldLabel='Tax (%):'
                        control={control}
                        error={errors.tax}
                        fieldName='tax'
                    />

                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    disabled={loading ? true : false}
                    onClick={handleSubmit(submitform)}
                >
                    {loading ? "Saving" : "Save"}
                </Button>
                {/* <Button
                    type="button"
                    color="secondary"
                    onClick={() => onCloseModal(
                        setValue("name", ""),
                        setValue("description", ""),
                        setValue("tax", ""),
                        setValue("category_id", ""),
                        setActiveData(null)

                    )}
                >
                    Close
                </Button> */}
            </ModalFooter>
        </Modal>

    )
}

export default AddSubCategory