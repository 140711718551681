
import React, { Fragment } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import PropTypes from 'prop-types';
const SearchHeader = ({ filterText, onFilter, onClear, formStyle = {}, inputStyle = {} }) => {
    return (
        <Fragment>
            <Form className="form-inline search-form" style={formStyle}>
                <FormGroup>
                    <Input
                        className={'form-control-plaintext'}
                        style={{
                            borderWidth: 0.2,
                            borderColor: 'gray',
                            width: 300,
                            paddingLeft: 5,
                            ...inputStyle, // Merge custom input styles from props
                        }}
                        type="search"
                        placeholder="Search.."
                        value={filterText}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                onClear();
                            } else {
                                onFilter(e);
                            }
                        }}
                    />
                </FormGroup>
            </Form>
        </Fragment>
    );
};
// Define PropTypes
SearchHeader.propTypes = {
    filterText: PropTypes.string.isRequired,
    onFilter: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    formStyle: PropTypes.object,
    inputStyle: PropTypes.object,
};

// Define default props
SearchHeader.defaultProps = {
    formStyle: {},
    inputStyle: {},
};

export default SearchHeader;
