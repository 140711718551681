import { Disc } from "react-feather"
import axios from "../../customAxios"
import {
    GET_UNIT_FAIL,
    GET_UNIT_SUCCESS,
    CREATE_UNIT_FAILER,
    CREATE_UNIT_SUCCESS,
    UNIT_DEL_SUCCESS,
    UNIT_DEL_FAILER,
    UNIT_EDIT_SUCCESS,
    UNIT_EDIT_FAIL,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    DEL_CATEGORY_SUCCESS,
    DEL_CATEGORY_FAIL,
    EDIT_CATEGORY_SUCCESS,
    EDIT_CATEGORY_FAIL,
    CREATE_SUBCATEGORY_SUCCESS,
    CREATE_SUBCATEGORY_FAIL,
    EDIT_SUBCATEGORY_SUCCESS,
    EDIT_SUBCATEGORY_FAIL,
    DEL_SUBCATEGORY_SUCCESS,
    DEL_SUBCATEGORY_FAIL,
    GET_SUBCATEGORY_SUCCESS,
    GET_SUBCATEGORY_FAIL,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAIL,
    GET_SUBCATEGORY_ID_SUCCESS,
    GET_SUBCATEGORY_ID_FAIL,
    CREATE_FEATURE_SUCCESS,
    CREATE_FEATURE_FAIL,
    GET_FEATURED_SUCCESS,
    GET_FEATURED_FAIL,
    DEL_FEATURE_SUCCESS,
    DEL_FEATURE_FAIL,
    EDIT_FEATURE_SUCCESS,
    EDIT_FEATURE_FAIL,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_FAIL,
    DEL_PRODUCT_SUCCESS,
    DEL_PRODUCT_FAIL,
    GET_SINGLE_PROD_SUCCESS,
    GET_SINGLE_PROD_FAILURE,
    EDIT_PRODUCT_PRICE_SUCCESS,
    EDIT_PRODUCT_PRICE_FAIL,
    EDIT_PRODUCT_SUCCESS,
    EDIT_PRODUCT_FAIL,
    ENABLE_PRODUCT_SUCCESS,
    ENABLE_PRODUCT_FAIL
} from "../constants/productConstants"
import { LOADING } from "../constants/userConstants"

export const CreateUnit = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-unit`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: CREATE_UNIT_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: CREATE_UNIT_FAILER,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}

export const getuniltList = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/units`).then(async response => {
        let data = response.data.data;
        dispatch({
            type: GET_UNIT_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payloade: false
        });
    }).catch(err => {
        dispatch({
            type: GET_UNIT_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}



export const deluniltList = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/delete-unit/${id}`).then(async response => {
        let data = response.data.data;
        dispatch({
            type: UNIT_DEL_SUCCESS,
            payload: id
        });
        dispatch({
            type: LOADING,
            payloade: false
        });
    }).catch(err => {
        dispatch({
            type: UNIT_DEL_FAILER,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}

export const EditUnit = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`admin/edit-unit`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: UNIT_EDIT_SUCCESS,
            payload: data

        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(err => {
        dispatch({
            type: UNIT_EDIT_FAIL,
            payload: err
        })
    })

}


//*............................CATEGORY..............................................................

export const CreateCategory = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })


    await axios.post(`/admin/create-category`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: CREATE_CATEGORY_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch((err => {

        dispatch({
            type: CREATE_CATEGORY_FAIL,
            payload: err
        })
    }))

}

export const GetCategory = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/categories`).then(async response => {
        let data = response.data.data;

        dispatch({
            type: GET_CATEGORY_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch((err => {
        dispatch({
            type: GET_CATEGORY_FAIL,
            payload: err
        })
    }))

}

export const DeleteCategory = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/category/${id}`).then(async response => {
        dispatch({
            type: DEL_CATEGORY_SUCCESS,
            payload: id
        });
        dispatch({
            type: LOADING,
            payload: false
        });

    }).catch(err => {
        dispatch({
            type: LOADING,
            payload: false
        });
        dispatch({
            type: DEL_CATEGORY_FAIL,
            payload: err
        })
    })


}


export const UpdateCategory = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-category`, data).then(async responce => {
        dispatch({
            type: EDIT_CATEGORY_SUCCESS,
            payload: responce.data.data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(err => {
        dispatch({
            type: EDIT_CATEGORY_FAIL,
            payload: err
        })
    })
}

//*............................SUB_CATEGORY..............................................................


export const CreateSubcategory = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`admin/create-sub-category`, data).then(async responce => {
        let data = responce.data.data
        dispatch({
            type: CREATE_SUBCATEGORY_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(err => {
        dispatch({
            type: CREATE_SUBCATEGORY_FAIL,
            payload: err
        })
    })
}




export const GetsubCategory = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`/admin/sub-categories`, data).then(async responce => {
        let data = responce.data.data
        dispatch({
            type: GET_SUBCATEGORY_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(err => {
        dispatch({
            type: GET_SUBCATEGORY_FAIL,
            payload: err
        })
    })



}


export const GetsubCategorybyid = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.get(`/admin/get-category/${id}`).then(async responce => {
        let data = responce.data.data
        dispatch({
            type: GET_SUBCATEGORY_ID_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(err => {
        dispatch({
            type: GET_SUBCATEGORY_ID_FAIL,
            payload: err
        })
    })



}

export const EditsubCategory = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/edit-sub-category`, data).then(async responce => {
        let data = responce.data.data
        dispatch({
            type: EDIT_SUBCATEGORY_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(err => {
        dispatch({
            type: EDIT_SUBCATEGORY_FAIL,
            payload: err
        })
    })
}


export const DelsubCategory = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.delete(`/admin/sub-category/${id}`).then(async responce => {
        let data = responce.data.data
        dispatch({
            type: DEL_SUBCATEGORY_SUCCESS,
            payload: id
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(err => {
        dispatch({
            type: DEL_SUBCATEGORY_FAIL,
            payload: err
        })
    })

}

//*'''''''''''''''''''''''ADD PRODUCTS''''''''''''''''''''''''''''''''''''


export const ADDPRODUCTS = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-product`, data).then(async response => {
        let data = response.data.data
        dispatch({
            type: CREATE_PRODUCT_SUCCESS,
            payload: data,

        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: CREATE_PRODUCT_FAIL,
            payload: error
        })
    })
}



export const editProductPrice = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/price-update`, data).then(async response => {
        let data = response?.data?.data
        dispatch({
            type: EDIT_PRODUCT_PRICE_SUCCESS,
            payload: data,
        })

        dispatch({
            type: LOADING,
            payload: false
        })
        
    }).catch(error => {
        dispatch({
            type: EDIT_PRODUCT_PRICE_FAIL,
            payload: error
        })
    })

}


export const getProducts = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/products`).then(async response => {
        let data = response?.data?.data
        dispatch({
            type: GET_PRODUCT_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: GET_PRODUCT_FAIL,
            payload: error
        })
    })

}

export const getSingleProductlist = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/product/${id}`).then(async response => {
        let data = response?.data?.data
        dispatch({
            type: GET_SINGLE_PROD_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: GET_SINGLE_PROD_FAILURE,
            payload: error
        })
    })

}

export const DELPRODUCT = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/product/${id}`).then(async response => {

        dispatch({
            type: DEL_PRODUCT_SUCCESS,
            payload: id
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: DEL_PRODUCT_FAIL,
            payload: error
        })
    })

}

export const editproductd = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-product`, data).then(async response => {
        let data = response?.data?.data
        dispatch({
            type: EDIT_PRODUCT_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: EDIT_PRODUCT_FAIL,
            payload: error
        })
    })

}


//*************ADD FEATURED************************ */

export const CREATEFEATURED = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/create-featured`, data).then(async response => {
        let data = response.data.data
        dispatch({
            type: CREATE_FEATURE_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: CREATE_FEATURE_FAIL,
            payload: error
        })
    })

}
export const GETFEATURED = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/featured`).then(async response => {
        let data = response.data.data
        dispatch({
            type: GET_FEATURED_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: GET_FEATURED_FAIL,
            payload: error
        })
    })

}

export const DELFEATURED = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.delete(`/admin/featured/${id}`).then(async response => {
        dispatch({
            type: DEL_FEATURE_SUCCESS,
            payload: id
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: DEL_FEATURE_FAIL,
            payload: error
        })
    })

}

export const EDITFEATURED = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-featured`, data).then(async response => {
        let data = response.data.data
        dispatch({
            type: EDIT_FEATURE_SUCCESS,
            payload: data
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch(error => {
        dispatch({
            type: EDIT_FEATURE_FAIL,
            payload: error
        })
    })

}

//*............................PRODUCT ENABLE DISABLE..............................................................

export const ProductEnableDisable = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })

    await axios.post(`/admin/product/status`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: ENABLE_PRODUCT_SUCCESS,
            payload: true
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    }).catch((err => {

        dispatch({
            type: ENABLE_PRODUCT_FAIL,
            payload: err
        })
    }))

}




