import axios from '../../customAxios';
import {
    CREATE_Notification_FAIL,
    CREATE_Notification_SUCCESS,
    CREATE_PROFILE_FAIL,
    CREATE_PROFILE_SUCCESS,
    CREATE_SLIDER_FAIL,
    CREATE_SLIDER_SUCCESS,
    DEL_Notification_FAIL,
    DEL_Notification_SUCCESS,
    DEL_SLIDER_FAIL,
    DEL_SLIDER_SUCCESS,
    GET_CUSTOMER_LIST_FAIL,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_PROFILE_FAIL,
    GET_PROFILE_SUCCESS,
    GET_SLIDER_FAIL,
    GET_SLIDER_SUCCESS,
    UPDATE_Notification_FAIL,
    UPDATE_Notification_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_SLIDER_FAIL,
    UPDATE_SLIDER_SUCCESS,
    CUSTOMER_ENABLE_FAIL,
    CUSTOMER_ENABLE_SUCCESS,
} from '../constants/settingConstants';
import { LOADING } from '../constants/userConstants';

export const CREATEPROFILE = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .post(`/admin/create-setting`, data)
        .then(async (response) => {
            let data = response.data.data;
            dispatch({
                type: CREATE_PROFILE_SUCCESS,
                payload: data,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: CREATE_PROFILE_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};

export const getProfile = () => async (dispatch) => {

    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/settings`).then(async response => {
        let data = response.data.data;
        dispatch({
            type: GET_PROFILE_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: GET_PROFILE_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}

export const EDITSETTING = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .post(`/admin/edit-setting`, data)
        .then(async (response) => {
            let data = response.data.data;
            dispatch({
                type: UPDATE_PROFILE_SUCCESS,
                payload: data,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: UPDATE_PROFILE_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};


/******************************NOTIFICATION************************************** */

export const CREATENOTIFICATION = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .post(`/admin/create-notification`, data)
        .then(async (response) => {
            let data = response.data.data;
            dispatch({
                type: CREATE_Notification_SUCCESS,
                payload: data,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: CREATE_Notification_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};
  
export const GETNOTIFICATION = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
}; 

export const DELNOTIFICATION = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .delete(`/admin/notification/${id}`)
        .then(async (response) => {
            dispatch({
                type: DEL_Notification_SUCCESS,
                payload: id,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: DEL_Notification_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};
  
export const EDITNOTIFICATION = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .post(`/admin/edit-notification`, data)
        .then(async (response) => {
            dispatch({
                type: UPDATE_Notification_SUCCESS,
                payload: data,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: UPDATE_Notification_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};

// /********************SLIDER****************************** /

export const GetSlider = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .get(`/admin/sliders`, data)
        .then(async (response) => {
            let data = response.data.data;
            dispatch({
                type: GET_SLIDER_SUCCESS,
                payload: data,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_SLIDER_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};

export const PostSlider = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .post(`/admin/create-slider`, data)
        .then(async (response) => {
            let data = response.data.data;
            dispatch({
                type: CREATE_SLIDER_SUCCESS,
                payload: data,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: CREATE_SLIDER_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};

export const delSlider = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .delete(`admin/slider/${id}`)
        .then(async (response) => {
            let data = response.data.data;
            dispatch({
                type: DEL_SLIDER_SUCCESS,
                payload: id,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: DEL_SLIDER_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};

export const UpdateSlider = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .post(`/admin/edit-slider`, data)
        .then(async (response) => {
            let data = response.data.data;
            dispatch({
                type: UPDATE_SLIDER_SUCCESS,
                payload: data,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: UPDATE_SLIDER_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};

//******************************CUSTOMER********************************** */
export const GetCustomerList =
    ({ page, perPage, filterText }) =>
        async (dispatch) => {
            dispatch({
                type: LOADING,
                payload: true,
            });

            // Add pagination parameters to the request (e.g., page and perPage)
            await axios
                .get(`/admin/customers`, {
                    params: {
                        page: page, // Current page number
                        limit: perPage, // Number of items per page
                        search: filterText,
                    },
                })
                .then(async (response) => {
                    const { data, total } = response.data; // Assume API returns `data` and `total` count
                    dispatch({
                        type: GET_CUSTOMER_LIST_SUCCESS,
                        payload: {
                            data, // List of customers for the current page
                            total, // Total number of customers (for pagination)
                        },
                    });
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: GET_CUSTOMER_LIST_FAIL,
                        payload: err,
                    });
                    dispatch({
                        type: LOADING,
                        payload: false,
                    });
                });
        };

//******************************CUSTOMER ENABLE DISABLE********************************** */

export const CustomerEnableDisable = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true,
    });
    await axios
        .post(`/admin/customer/status`, data)
        .then(async (response) => {
            let data = response.data.data;
            dispatch({
                type: CUSTOMER_ENABLE_SUCCESS,
                payload: data,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        })
        .catch((err) => {
            dispatch({
                type: CUSTOMER_ENABLE_FAIL,
                payload: err,
            });
            dispatch({
                type: LOADING,
                payload: false,
            });
        });
};

