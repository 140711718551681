import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Button, Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import CustomInput from "../../common/CustomInput";
import SelectInput from "../../common/SelectInput";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TableData from "./TableData";
import { useDispatch, useSelector } from "react-redux";
import { ADDPRODUCTS, editproductd, editProductPrice, GetCategory, GETFEATURED, getSingleProductlist, GetsubCategory, GetsubCategorybyid, getuniltList } from "../../../redux/actions/productActions";
import ImageUploading from 'react-images-uploading';
import moment from "moment"
import { toast } from 'react-toastify';
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import { useParams, useNavigate } from "react-router-dom";
// import BigInput from "../../common/BigInput";
import CustomTextArea from "../../common/CustomTextArea";

const AddProduct = () => {
    let { id } = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { getcatogrylist, subcategorylistbyid, featuredList, productcreated, error, singleproductList, productupdatedD } = useSelector(state => state.product)
    const [variantsData, setVariantsData] = useState([]);
    const [imageArray, setImageArray] = useState(null);
    const [featured, setfeatured] = useState([])
    const [Images, setImages] = useState([]);
    const [unitlistStore, setUnitlist] = useState([]);
    const [categoryArray, setCategoryArray] = useState(null)
    const [subcategoryArray, setSubCategoryArray] = useState([])
    const [featuredArray, setFeaturedArray] = useState(null)
    const [loading, setLoading] = useState(false)
    const maxNumber = 100;
    const acceptType = ['jpg', 'png', 'jpeg'];
    const resolutionWidth = 512;
    const resolutionHeight = 512;
    const maxFileSize = 2000000;

    useEffect(() => {
        resetForm();
        if (!getcatogrylist?.length) {
            dispatch(GetCategory())
        }
        if (!subcategorylistbyid?.length) {
            dispatch(GetsubCategory())
        }
        if (!featuredList?.length) {
            dispatch(GETFEATURED())
        }
        if (!unitlistStore?.length) {
            dispatch(getuniltList())
        } 
        if(id){
            dispatch(getSingleProductlist(id));
        }
    
        return () => {
            setImageArray(null);
        }
    }, []);

    useEffect(() => {
        setImageArray(null)
    }, [id, featuredList, dispatch])
      
    const resetForm = () => {
        reset();
        setVariantsData([]);
        setImageArray(null);
        setfeatured([]);
        setImages([]);
    };

    const schema = yup.object({
        name: yup.string().required("Name field is required"),
        category: yup.object().when("edit", {
            is: false,
            then: yup.object().required("Category Required"),
        }),
        description: yup
                    .string()
                    .max(255,"Product Description can not exceed 255 characters"),
        details: yup
                .string()
                .max(255,"Product Details can not exceed 255 characters"),
    }).required();
    const { handleSubmit, control, formState: { errors }, reset, setValue, watch, getValues } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            sub: true,
            name: '',
            description: '',
            details: ''
        }
    });
    useEffect(() => {
        let features = [];
        if (featuredList) {
            if (singleproductList?.featuredList) {
                featuredList?.map(feature => {
                    let findfeature = singleproductList?.featuredList?.find(prod => prod?._id === feature?._id);
                    if (findfeature) {
                        features.push({
                            ...feature,
                            checked: true
                        })
                    }
                    else {
                        features.push({
                            ...feature,
                            checked: false
                        })
                    }
                })
            }
            else {
                features = featuredList
            }
        }
        setFeaturedArray(features)

    }, [featuredList, singleproductList])

    useEffect(() => {
        if (id && singleproductList) {
            setFeaturedArray(null)
            setValue("name", singleproductList?.name)
            setValue("category", {
                value: singleproductList?.categories?._id,
                label: singleproductList?.categories?.name,
            })
            setValue("subcategory", {
                value: singleproductList?.subcategories?._id,
                label: singleproductList?.subcategories?.name,
            })
            setValue("description", singleproductList?.description === null ? "" : singleproductList?.description)
            setValue("details", singleproductList?.details === null ? "" : singleproductList?.details)
            let images = singleproductList?.image?.map(im => {
                return `${singleproductList?.imageBasePath}${im}`
            })
            setImageArray(images)
            let units = singleproductList?.categories?.unit.map(un => {
                return {
                    value: un.id,
                    label: un.name
                }
            })
            setUnitlist(units)
            let variants = singleproductList?.variants?.map(vari => {
                return {
                    uid: vari._id,
                    id: {
                        value: vari?.units?._id,
                        label: vari?.units?.name
                    },
                    name: vari.name,
                    description: vari.description,
                    selling: vari.sellingPrice,
                    regular: vari.costPrice,
                    offer: vari.offerPrice,
                    fromDate: vari.fromDate ? moment(vari.fromDate) : null,
                    toDate: vari.toDate ? moment(vari.toDate) : null
                }
            })
            setVariantsData(variants)
            let features = [];
            if (singleproductList?.featuredList) {
                featuredList?.map(feature => {
                    let findfeature = singleproductList?.featuredList?.find(prod => prod?._id === feature?._id);
                    if (findfeature) {
                        features.push({
                            ...feature,
                            checked: true
                        })
                    }
                    else {
                        features.push({
                            ...feature,
                            checked: false
                        })
                    }
                })
                setFeaturedArray(features)
            }
            dispatch(GetsubCategorybyid(singleproductList?.categories?._id))
        }
        if (!id) {
            setValue("name", '')
            setValue("category", null)
            setValue("subcategory", null)
            setValue("description", '')
            setValue("details", '')
            setImageArray(null)
            setUnitlist([])
            setVariantsData([])
            let features = [];
            featuredList?.map(feature => {
                features.push({
                    ...feature,
                    checked: false
                })
            })
            setFeaturedArray(features)

        }

    }, [id, singleproductList])

    useEffect(() => {
        if (getcatogrylist) {
            setValue("categoryList", getcatogrylist)
            let categories = [{ value: '', label: 'select' }]
            getcatogrylist.map(cat => {
                categories.push({
                    value: cat?._id,
                    label: cat?.name
                })
            })
            setCategoryArray(categories)
            if (subcategorylistbyid) {
                let subs = subcategorylistbyid.map(su => {
                    return {
                        value: su._id,
                        label: su.name
                    }
                })

                if (subs.length === 0) {
                    setValue("sub", false)
                }

                setSubCategoryArray(subs)
            }
        }        
    }, [getcatogrylist, subcategorylistbyid])


    useEffect(() => {
        if ((getValues('category') && !id)) {
        setValue('subcategory', '')
        }
    }, [getValues('category')])
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === "category" && type === "change" && value?.category?.value) {
                setValue('subcategory', null); // Resetting subcategory to null
                populateSubCategory(value?.category?.value, value.categoryList)
            }
        });
        return () => subscription.unsubscribe();
    }, [watch])

    const populateSubCategory = (category, categoryList) => {
        let list = categoryList.find((res) => res._id === category)
        let units = list.unit.map(un => {
            return {
                value: un.id,
                label: un.name
            }
        })
        setUnitlist(units)
        dispatch(GetsubCategorybyid(category))
    }
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        var reader = new FileReader();
        setImages(imageList);
    };
   
    const handlechange = (e, checked) => {
        if (checked) {
            let find = featured.find(feat => feat.id === e);

            if (!find) {
                setfeatured(prv => [...prv, { id: e }]);
            }
        }
        else {
            let features = featured.filter(feat => feat.id !== e);
            setfeatured(features)
        }
    }

    const submitform = (data) => {
        setLoading(true)
        let units = [];
        variantsData.map(variant => {
            let vari = {
                name: variant.name,
                description: variant.description,
                costPrice: variant.regular,
                sellingPrice: variant.selling,
                offerPrice: variant.offer,
                fromDate: variant.fromDate ? moment(variant.fromDate).format("YYYY-MM-DD") : null,
                toDate: variant.toDate ? moment(variant.toDate).format("YYYY-MM-DD") : null
            }
            let findUnit = units.find(un => un.id === variant.id.value)
            if (findUnit) {
                findUnit.variants.push(vari);

                units = units.map(un => un.id === variant.id.value ? findUnit : un)
            }
            else {
                let newunit = {
                    id: variant.id.value,
                    name: variant.id.label,
                    variants: [vari]
                }
                units.push(newunit)
            }
        })
        let features = [];
        featuredArray.map(fea => {
            if (fea.checked) {
                features.push({
                    id: fea._id
                })
            }
        })
        const formData = new FormData();
        formData.append("description", data?.description);
        formData.append("details", data?.details);
        if(features.length > 0 ){
            formData.append("featured", JSON.stringify(features));
        }
        Images && Images?.map((img, i) => {
            formData.append(`image[${i}]`, img.file);
        })
        if (!id) {
            formData.append("name", data?.name);
            formData.append("category", JSON.stringify({ id: data?.category?.value, name: data?.category?.label }));
            if(data?.subcategory.value){
                formData.append("subCategory", JSON.stringify({ id: data?.subcategory?.value, name: data?.subcategory?.label }))
            }
            // formData.append("subCategory", data?.subcategory.value ? JSON.stringify({ id: data?.subcategory?.value, name: data?.subcategory?.label }) : null)
            formData.append("units", JSON.stringify(units));
            dispatch(ADDPRODUCTS(formData)).finally(() => setLoading(false));
        }
        else {
            formData.append('id', id);
            formData.append("category", JSON.stringify({ id: data?.category?.value, name: data?.category?.label }));
            console.log(data?.subcategory?.value,"subcategory")
            if(data?.subcategory?.value){
                formData.append("subCategory", JSON.stringify({ id: data?.subcategory?.value, name: data?.subcategory?.label }))
            }
            formData.append('status', singleproductList?.status);
            dispatch(editproductd(formData)).finally(() => setLoading(false));
        }
    }
    useEffect(() => {
        if (productcreated) {
            toast.success("Product Created successfully");
            setValue("name", "")
            setValue("category", "")
            setValue("subcategory", "")
            setValue("description", "")
            setValue("details", "")
            setImages([])
            setVariantsData([])
            dispatch({
                type: RESET_ERROR
            })
            navigate('/products/products')
        }
        if (productupdatedD) {
            toast.success("Product updated successfully");
            navigate('/products/products')
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [productcreated, productupdatedD])
    useEffect(() => {
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error])
    const saveVariantsPrice = (variants) => {
        let units = [];
        variants.map(variant => {
            let vari = {
                name: variant.name,
                description: variant.description,
                costPrice: variant.regular,
                sellingPrice: variant.selling,
                offerPrice: variant.offer,
                fromDate: variant.fromDate ? moment(variant.fromDate).format("YYYY-MM-DD") : null,
                toDate: variant.toDate ? moment(variant.toDate).format("YYYY-MM-DD") : null
            }
            let findUnit = units.find(un => un.id === variant.id.value)
            if (findUnit) {
                findUnit.variants.push(vari);

                units = units.map(un => un.id === variant.id.value ? findUnit : un)
            }
            else {
                let newunit = {
                    id: variant.id.value,
                    name: variant.id.label,
                    variants: [vari]
                }
                units.push(newunit)
            }
        })
        let input = {
            category_id: singleproductList?.categories?._id,
            subcategory_id: singleproductList?.subcategories?._id,
            units: units,
            id: id
        }
        dispatch(editProductPrice(input))
    }
    const addVariants = (value) => {
        let variants = variantsData.filter(vari => vari.uid !== value.uid)
        let newVariants = variants ? [...variants, value] : [value]
        if (id) {
            saveVariantsPrice(newVariants)
        }
        else {
            setVariantsData(newVariants)
        }
    }
    const deleteVariants = (variantId) => {
        let variants = variantsData.filter(vari => vari.uid !== variantId)
        //setVariantsData(variants)
        if (id) {
            saveVariantsPrice(variants)
        }
    }
    const handleFeaturechange = (id, value) => {
        setFeaturedArray(current => current.map(fe => {
            if (fe._id === id) {
                return {
                    ...fe,
                    checked: value
                }
            }
            else {
                return fe
            }
        }))
    }
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === "category" && type === "change" && value?.category?.value) {
                // Reset subcategory when category changes
                setValue('subcategory', null); // Resetting subcategory to null
                populateSubCategory(value?.category?.value, value.categoryList);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <Fragment>
            <Breadcrumb title={id ? "Edit Product" : "Add Product"} parent="Digital" />
            <Container fluid={true}>
                <Row className="product-adding">
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <div className="digital-add needs-validation" >
                                    <Row xl="2">
                                        <CustomInput
                                            fieldLabel='Product Name :'
                                            control={control}
                                            error={errors.name}
                                            fieldName='name'
                                            disabled={!id ? false : true}
                                        />
                                        <SelectInput
                                            fieldLabel='Category Name :'
                                            control={control}
                                            error={errors.category}
                                            fieldName='category'
                                            options={categoryArray}
                                        />
                                    </Row>
                                    <Row xl="2">
                                        <Col xl="6" >
                                            <SelectInput
                                                fieldLabel='Sub Category:'
                                                control={control}
                                                error={errors.subcategory}
                                                fieldName='subcategory'
                                                setValue={setValue}
                                                options={subcategoryArray}
                                            />
                                            <CustomTextArea
                                                fieldLabel='Product Details :'
                                                control={control}
                                                error={errors.details}
                                                fieldName='details'
                                                type="textarea"
                                            />
                                            <CustomTextArea
                                                fieldLabel='Product Description :'
                                                control={control}
                                                error={errors.description}
                                                fieldName='description'
                                                type="textarea"
                                            />
                                        </Col>
                                        <Col xl={6}>
                                            <div style={{ marginBottom: "30px", display: "flex", gap: "10px" }}>
                                                {imageArray?.map((res, i) => (
                                                    <img src={`${res}`} alt="images"
                                                        style=
                                                        {{
                                                            width: "80px",
                                                            height: "80px"
                                                        }}
                                                    ></img>
                                                ))}
                                            </div>
                                            <div style={{ border: "2px dotted", borderRadius: "10px", padding: "10px" }}>
                                                <ImageUploading
                                                    multiple
                                                    value={Images}
                                                    onChange={onChange}
                                                    maxNumber={maxNumber}
                                                    acceptType={acceptType}
                                                    dataURLKey="data_url"
                                                    resolutionType="ratio"
                                                    resolutionWidth={resolutionWidth}
                                                    resolutionHeight={resolutionHeight}
                                                    maxFileSize={maxFileSize}
                                             >
                                                    {({
                                                        imageList,
                                                        onImageUpload,
                                                        onImageUpdate,
                                                        onImageRemove,
                                                        dragProps,
                                                        errors
                                                    }) => (
                                                        <div className="upload__image-wrapper" >
                                                            <div>
                                                                {errors?.maxNumber && <span style={{ color: "red" }}>Number of selected images exceed maxNumber</span>}
                                                                {errors?.maxFileSize && <span style={{ color: "red" }}> Selected file size exceed maxFileSize</span>}
                                                                {errors?.resolution && <span style={{ color: "red" }}>Image resolution should be greater than 512 * 512 and should be equal width and height </span>}
                                                            </div>
                                                            <div style={{ paddingTop: "10px", paddingbottom: "50px", display: "flex", justifyContent: "center" }}>
                                                                <button
                                                                    style={{ border: "none", padding: "5px 10px", cursor: "pointer", background: "#13c9ca", borderRadius: "10px", width: "90%", fontSize: "16px", fontWeight: "bold" }}
                                                                    onClick={onImageUpload}
                                                                    {...dragProps}
                                                                >
                                                                    Upload Image
                                                                </button>
                                                            </div>

                                                            &nbsp;
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    gap: "20px",
                                                                    flexWrap: "wrap"
                                                                }}>
                                                                {imageList.map((image, index) => (

                                                                    <div key={index} className="image-item"
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column",
                                                                            justifyContent: "center",
                                                                            alignItems: "center"
                                                                        }}>
                                                                        <img src={image['data_url']} alt="" width="100" height="100" />
                                                                        <div className="image-item__btn-wrapper"
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "5px",
                                                                                paddingTop: "10px"
                                                                            }}>
                                                                            <button onClick={() => onImageUpdate(index)}
                                                                                style={{
                                                                                    border: "none",
                                                                                    cursor: "pointer",
                                                                                    background: "rgb(19 201 202 / 49%)",
                                                                                    borderRadius: "5px"
                                                                                }}>
                                                                                Update
                                                                            </button>
                                                                            <button onClick={() => onImageRemove(index)}
                                                                                style={{
                                                                                    border: "none",
                                                                                    cursor: "pointer",
                                                                                    background: "#ff8084",
                                                                                    borderRadius: "5px"
                                                                                }}>
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </ImageUploading>
                                                <span>Recommended Image resolution 512*512</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Col xl="12">
                    <Card>
                        <CardBody>
                            <div >
                                <h1 style={{ fontSize: "12px" }}>Feature List</h1>
                            </div>
                            <Form>
                                <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "baseline" }}>
                                    {
                                        featuredArray?.map((x) => {
                                            return <div style={{ display: "flex", gap: "20px", paddingTop: "10px" }}>
                                                <input type="checkbox" value={x._id} checked={x.checked} onChange={(e) => handleFeaturechange(x._id, e.target.checked)} />
                                                <label>{x.name}</label>
                                            </div>
                                        })
                                    }
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl="12">
                    <Card>
                        <CardBody>
                            {unitlistStore && <TableData
                                onDelete={(id) => deleteVariants(id)}
                                variant={variantsData}
                                onAdd={(value) => addVariants(value)}
                                setVariantsData={setVariantsData}
                                unitliststore={unitlistStore}
                            />}
                            <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: 10, paddingBottom: 10 }}>
                                <Button onClick={handleSubmit(submitform)} disabled={variantsData?.length === 0 || loading ? true : false}>{loading ? "Saving" : "Submit"}</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </Fragment>
    );
};
export default AddProduct;
