import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Button, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, ButtonGroup } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from "../common/CustomInputPast";
import { GetreportsListdata, GetReportsDownloadData } from '../../redux/actions/reportsAction'; // Import your action here
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
const TaxReports = () => {
    const dispatch = useDispatch();
    const { reportsListdata, totalCount } = useSelector((state) => state.reports); // Get total count from your Redux store
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [fromDate, setFromDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const schema = yup.object({
        fromDate: yup.string().required("From date required"),
        toDate: yup.string().required("To date required"),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            fromDate: moment().startOf("month").format("YYYY-MM-DD"),
            toDate:moment().format("YYYY-MM-DD")
        },
    });
    const subHeaderComponentMemo = React.useMemo(() => {
        const resetFun = () => {
            // Reset form fields explicitly
            reset({
                fromDate: null,
                toDate: null,
            });
            // Reset the state for filters
            setFromDate(null);
            setToDate(null);
            // Reset pagination
            setPage(1);
            // Fetch the default data without any filters
            dispatch(GetreportsListdata({ page: 1, perPage: rowsPerPage }));
            window.location.reload();
        };
        const reportsDownload = async (type) => {
            try {
                await dispatch(GetReportsDownloadData({ type: type, fromDate: fromDate, toDate: toDate }))
            } catch (err) {
                toast.error("Download Failed");
            }
        };
        const filterOrders = (data) => {
            const fromDateFormatted = moment(data?.fromDate).format("YYYY-MM-DD");
            const toDateFormatted = moment(data?.toDate).format("YYYY-MM-DD");

            if (!data.toDate || !moment(data.toDate).isValid()) {
                toast.error(`To date is required `);
                return false;
            }
            if (!data.fromDate || !moment(data.fromDate).isValid()) {
                toast.error(`From date is required`);
                return false;
            }
            if (moment(toDateFormatted).isBefore(fromDateFormatted)) {
                toast.error(`To date cannot be earlier than from date`);
                return false;
            }
            setFromDate(fromDateFormatted); // Set state for fromDate
            setToDate(toDateFormatted); // Set state for toDate
            // Dispatch the action with formatted dates and reset the page to 1 for filtered results
            handlePageChange(1)
            dispatch(GetreportsListdata({
                page: 1,
                perPage: rowsPerPage,
                fromDate: fromDateFormatted,
                toDate: toDateFormatted
            }));
        };

        return (
            <div className="filter-wrapper"
            >
                <div className="input-cols-date">
                    <CustomInput
                        inputGroupClasses="date-input first-date-input"
                        fieldLabel="From Date"
                        control={control}
                        error={errors.fromDate}
                        fieldName="fromDate"
                        type={"date"}
                    />
                    <CustomInput
                        inputGroupClasses="date-input"
                        fieldLabel="To Date"
                        control={control}
                        error={errors.toDate}
                        fieldName="toDate"
                        type={"date"}
                    />
                    <Button
                        className="btn-sub"
                        type="button"
                        color="primary"
                        onClick={handleSubmit(filterOrders)}
                    >
                        Submit
                    </Button>
                    <Button className="btn-sub" type="button" color="primary" onClick={resetFun}>
                        Reset
                    </Button>
                </div>
                <div className="input-cols-download">
                    <ButtonGroup className="btn-subd">
                        <UncontrolledDropdown>
                            <DropdownToggle caret color="primary">
                                Download
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => reportsDownload('pdf')}><i className="fa fa-file-pdf-o down-load-icon" aria-hidden="true" ></i> Pdf</DropdownItem>
                                <DropdownItem onClick={() => reportsDownload('xlsx')}><i className="fa fa-file-excel-o down-load-icon" aria-hidden="true" ></i> Excel</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonGroup>
                </div>
            </div>
        );
    }, [control, errors, rowsPerPage, dispatch, reset, page]);

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    var columns = [
        {
            name: <b>{Capitalize('Order ID')}</b>,
            header: <b>{Capitalize('Order ID')}</b>,
            selector: (row) => row?.order_id,
            style: {
                textAlign: 'center',
            },
        },
        {
            name: <b>{Capitalize('Order Placed On')}</b>,
            header: <b>{Capitalize('Order Placed On')}</b>,
            selector: (row) => `${moment(row?.orderDate).format("DD-MM-YYYY")}`,
            style: {
                textAlign: 'center',
            },
        },
        {
            name: <b>{Capitalize('payment Type')}</b>,
            header: <b>{Capitalize('paymentType')}</b>,
            style: {
                textAlign: 'center',
            },
            selector: (row) => row?.paymentType,
        },
        {
            name: <b>{Capitalize('Order Total')}</b>,
            header: <b>{Capitalize('Order Total')}</b>,
            selector: (row) => {
                // Format the currency value
                let formattedTaxAmount = new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR',
                    minimumFractionDigits: 2,
                }).format(row?.total || 0);
                // Add a space after the currency symbol (₹)
                formattedTaxAmount = formattedTaxAmount.replace('₹', '₹ ');
                return formattedTaxAmount;
            },
            style: {
                textAlign: 'center',
            },
        },
        {
            name: <b>{Capitalize('Tax Amount')}</b>,
            header: <b>{Capitalize('Tax Amount')}</b>,
            selector: (row) => {
                // Format the currency value
                let formattedTaxAmount = new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR',
                    minimumFractionDigits: 2,
                }).format(row?.tax || 0);
                // Add a space after the currency symbol (₹)
                formattedTaxAmount = formattedTaxAmount.replace('₹', '₹ ');
                return formattedTaxAmount;
            },
            style: {
                textAlign: 'center',
            },
        },
    ];
    // Function to handle data fetching with pagination parameters
    const fetchData = (page, rowsPerPage, fromDate, toDate) => {
        dispatch(GetreportsListdata({ page, perPage: rowsPerPage, fromDate, toDate }));
    };
    // Update pagination and fetch data whenever page or rowsPerPage changes
    useEffect(() => {
        fetchData(page, rowsPerPage, fromDate, toDate);
    }, [page, rowsPerPage, fromDate, toDate]); // Add fromDate and toDate as dependencies

    useEffect(() => {
        // Set total rows from the response (if provided by API)
        if (totalCount) {
            setTotalRows(totalCount);
        }
    }, [totalCount]);
    // Function to handle page changes
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    // Function to handle rows per page change
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(1); // Reset to page 1 whenever rows per page changes
    };
    return (
        <>
            <Breadcrumb title="Tax reports" parent="Reports" />
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical sales-list">
                                    <DataTable
                                        columns={columns}
                                        data={(reportsListdata) ? reportsListdata : []}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalCount} // Total rows from server
                                        onChangePage={handlePageChange} // Handle page change
                                        onChangeRowsPerPage={handleRowsPerPageChange} // Handle rows per page change
                                        paginationRowsPerPageOptions={[10, 15, 25, 50]} // Page size options
                                        paginationPerPage={rowsPerPage} // Initial page size
                                        paginationComponentOptions={{
                                            rowsPerPageText: 'Rows per page:',
                                            rangeSeparatorText: 'on',
                                        }}
                                        fixedHeader
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default TaxReports;
