import React, { useState, useEffect,useRef  } from "react";
import {
  Modal,
  Button,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../common/SelectInput";
import {
  EditorderStatus,
  getOrderStatusById,
} from "../../redux/actions/OrderActions";
import CustomInput from "../common/CustomInput";
import PropTypes from "prop-types";

const OrderStatusModal = ({ openModal, onCloseModal, data }) => {
  const dispatch = useDispatch();
  const { orderstatusList } = useSelector((state) => state.orders);
  const [options, setOptions] = useState([]);
 
  const defaultPaymentStatus = [
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
    { value: "Failed", label: "Failed" },
  ];
  
  const [paymentStatus, setPaymentStatus] = useState(defaultPaymentStatus);
  const [statusName, setStatusName] = useState("");
  const [loading, setLoading] = useState(false);

  // Define regex patterns for validation
  const alphanumericWithSpecialCharsRegex = /^https?:\/\/[a-zA-Z0-9=\/\-?\.:\[#&\]]+$/;


  const schema = yup.object().shape({
    ...(statusName === "Shipped" && {
      consignmentNumber: yup
        .string()
        .nullable()
        .required("Consignment Number is required")
        .matches(
          /^[a-zA-Z0-9-_]+$/,
          "Consignment Number must be alphanumeric and can include '-' and '_'"
        )
        .matches(
          /^(?![-_]).*(?<![-_])$/,
          "Consignment Number should not start or end with special characters"
        )
        .min(3, "Consignment Number must be at least 3 characters long")
        .max(15, "Consignment Number cannot exceed 15 characters"),
      consignmentLink: yup
        .string()
        .nullable()
        .required("Consignment Link is required")
        .matches(
          alphanumericWithSpecialCharsRegex,
          "Consignment Link must be valid"
        )
        .min(3, "Consignment Link must be at least 3 characters long")
        .max(250, "Consignment Link cannot exceed 250 characters"),
    }),
  });

  const previousStatusRef = useRef(null); // To track the previous status
  const handleStatusChange = (selectedOption) => {
    const selectedStatus = selectedOption?.value;
    if (selectedStatus === "Cancelled") {
      if (!window.confirm("Are you sure you wish to cancel this order?")) {
        // If user cancels, revert to the previous status
        setValue("Status", previousStatusRef.current);
        setStatusName(previousStatusRef.current?.value);
        return;
      }
    }
    // Update the previous status ref
    previousStatusRef.current = selectedOption;
    // Update the state with the new status
    setStatusName(selectedStatus);
    setValue("Status", selectedOption);
  };

  useEffect(() => {
    if (orderstatusList) {
      const statuss = orderstatusList.map((st) => ({
        label: st,
        value: st,
      }));

      // Ensure current order status is at the beginning of the options
      if (data && !statuss.some((option) => option.value === data.orderStatus)) {
        statuss.unshift({
          label: data.orderStatus,
          value: data.orderStatus,
        });
      }

      setOptions(statuss);
    }
  }, [orderstatusList, data]);
  

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data) {
      const currentStatus = data.orderStatus;
      const currentPaymentStatus = data.paymentStatus;
  
      // Set the correct payment status options based on the current payment status
      if (currentPaymentStatus.toLowerCase() === "paid" || currentPaymentStatus.toLowerCase() === "failed") {
        setPaymentStatus([{ value: currentPaymentStatus, label: currentPaymentStatus }]);
      } else {
        setPaymentStatus(defaultPaymentStatus);
      }
  
      // Check if current status matches any option
      const selectedStatus = options.find(
        (res) => res?.value?.toLowerCase() === currentStatus?.toLowerCase()
      ) || { value: currentStatus, label: currentStatus }; // Fallback to the current status if not found
  
      reset({
        Status: selectedStatus,
        paymentStatus: { value: currentPaymentStatus, label: currentPaymentStatus },
        consignmentNumber: data.consignmentNumber,
        consignmentLink: data.consignmentLink,
      });
  
      setStatusName(currentStatus);
    }
  }, [data, options, reset]);
  

  useEffect(() => {
    if (openModal && data?._id) {
      dispatch(getOrderStatusById(data._id)).catch((error) => {
        setOptions([]);
      });
    }
  }, [openModal, data?._id, dispatch]);
  

  const SubmitForm = (datas) => {
    setLoading(true);
    const orderStatusValue = datas.Status
      ? datas.Status.value
      : data?.orderStatus;

    let val = {
      orderID: data?._id,
      orderStatus: orderStatusValue,
      paymentStatus: datas?.paymentStatus?.value || data.paymentStatus,
      consignmentNumber: datas?.consignmentNumber,
      consignmentLink: datas?.consignmentLink,
    };

    dispatch(EditorderStatus(val))
      .finally(() => setLoading(false));
      
    onCloseModal();
  };

  useEffect(() => {
    if (!openModal) {
      reset(); 
      clearErrors(); 
      setPaymentStatus(defaultPaymentStatus);
    }
  }, [openModal, reset, clearErrors]);

  return (
    <Modal isOpen={openModal} toggle={onCloseModal}>
      <ModalHeader toggle={onCloseModal}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Edit Status
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <SelectInput
            fieldLabel={
              <span>
                Status <span style={{ color: "red" }}>*</span>
              </span>
            }
            control={control}
            error={errors.Status}
            fieldName="Status"
            options={options}
            onChangeValue={handleStatusChange}
            selectvalue={options.find((option) => option.value === statusName)}
          />
          {statusName === "Shipped" && (
            <>
              <CustomInput
                fieldLabel={
                  <span>
                    Consignment Number <span style={{ color: "red" }}>*</span>
                  </span>
                }
                control={control}
                error={errors.consignmentNumber}
                fieldName="consignmentNumber"
              />
              <CustomInput
                fieldLabel={
                  <span>
                    Consignment Link <span style={{ color: "red" }}>*</span>
                  </span>
                }
                control={control}
                error={errors.consignmentLink}
                fieldName="consignmentLink"
              />
            </>
          )}
          <SelectInput
            fieldLabel={
              <span>
                Payment Status <span style={{ color: "red" }}>*</span>
              </span>
            }
            control={control}
            error={errors.paymentStatus}
            fieldName="paymentStatus"
            options={paymentStatus}
            isDisabled={true} // Disable payment status selection
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          disabled={loading}
          onClick={handleSubmit(SubmitForm)}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// Define propTypes for the component
OrderStatusModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    orderStatus: PropTypes.string.isRequired,
    paymentStatus: PropTypes.string.isRequired,
    consignmentNumber: PropTypes.string,
    consignmentLink: PropTypes.string,
  }).isRequired,
};

export default OrderStatusModal;
