/********************REPORTS LIST**********************/
export const GET_REPORTS_LIST_SUCCESS = 'GETCUTOMERLISTSUCCESS';
export const GET_REPORT_LIST_FAIL = 'GETCUSTOMERLISTFAIL';
export const LOADING = 'LOADING';
export const RESET_ERROR = 'RESET_ERROR';
export const GET_DOWNLOAD_DATA_SUCESS = 'GETDOWNLOADDATASUCESS'
export const GET_DOWNLOAD_DATA_FAIL = 'GETDOWNLOADDATAFAIL'
export const GET_REPORTS_SALES_SUCCESS = 'GETCUTOMERSALESSUCCESS'
export const GET_REPORTS_SALES_FAIL = 'GETCUTOMERSALESFAIL'
export const GET_REPORTS_SALES_DOWNLOAD_SUCCESS = 'GETCUTOMERSALESDOWNLADSUCCESS'
export const GET_REPORTS_SALES_DOWNLOAD_FAIL = 'GETCUTOMERSALESDOWNLADFAIL'
