import axios from "../../customAxios"
import { EDIT_STATUS_FAIL, EDIT_STATUS_SUCCESS, GET_ORDER_FAIL, GET_ORDER_SUCCESS, GET_SINGLE_ORDER_FAIL, GET_SINGLE_ORDER_SUCCESS, GET_STATUS_ORDER_FAIL, GET_STATUS_ORDER_SUCCESS, DOWNLOAD_INVOICE_SUCCESS, DOWNLOAD_INVOICE_FAIL } from "../constants/orderConstants"
import { LOADING } from "../constants/userConstants"



export const getOrderList = (page) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/orders`).then(response => {
        let data = response.data.data;


        dispatch({
            type: GET_ORDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {

        dispatch({
            type: GET_ORDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}

export const filterOrder = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/orderfilter`, data).then(async response => {
        let data = response.data.data;
        dispatch({
            type: GET_ORDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: GET_ORDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}


export const getSingleOrderList = (id) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/order/${id}`).then(async response => {
        let data = response.data.data;
   
        dispatch({
            type: GET_SINGLE_ORDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type: GET_SINGLE_ORDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}


export const EditorderStatus = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.post(`/admin/edit-order`,data).then(async response => {
        let data = response.data.data;
        dispatch({
            type:EDIT_STATUS_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:EDIT_STATUS_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })
}


export const getOrderStatus = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    await axios.get(`/admin/orderstatus`).then(async response => {
        let data = response.data.data;
   
        dispatch({
            type:GET_STATUS_ORDER_SUCCESS,
            payload: data
        });
        dispatch({
            type: LOADING,
            payload: false
        });
    }).catch(err => {
        dispatch({
            type:GET_STATUS_ORDER_FAIL,
            payload: err
        })
        dispatch({
            type: LOADING,
            payload: false
        })
    })


}
/* Download Invoice */
export const downloadInvoice = (data) => async (dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    });

    try {
        // Await the axios request directly
        const response = await axios.get(`/admin/invoices/${data}/download`, {
            responseType: 'blob'  // Set the response type to 'blob'
        });

        // Create a blob URL for the downloaded file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invoice_${data}.pdf`);

        // Append the link to the document body and trigger a click to download
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Dispatch success action
        dispatch({
            type: DOWNLOAD_INVOICE_SUCCESS,
            payload: response.data
        });
    } catch (err) {
        // Dispatch failure action with the error
        dispatch({
            type: DOWNLOAD_INVOICE_FAIL,
            payload: err
        });
        throw err; // Rethrow the error to be caught by the caller
    } finally {
        // Ensure the loading state is reset no matter what
        dispatch({
            type: LOADING,
            payload: false
        });
    }
};



export const getOrderStatusById = (orderId) => async (dispatch) => {
    dispatch({
      type: LOADING,
      payload: true,
    });

    return axios
      .get(`/admin/orderstatus?orderId=${orderId}`)
      .then((response) => {
        const data = response.data.data;
  
        dispatch({
          type: GET_STATUS_ORDER_SUCCESS,
          payload: data,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
  
        return data; // Resolve with data
      })
      .catch((err) => {
        dispatch({
          type: GET_STATUS_ORDER_FAIL,
          payload: err,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
  
        throw err; // Reject with error
      });
  }; 
  