import React from 'react'
import { FormGroup, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import moment from 'moment';
import PropTypes from 'prop-types';  // Import PropTypes

const CustomInput = ({ fieldName, control, fieldLabel, placeholder, error, type, max, disabled, inputGroupClasses }) => {
    return (
        <>
            <FormGroup className={inputGroupClasses}>
                <Label
                    htmlFor="recipient-name"
                    className="col-form-label"
                >
                    {fieldLabel}
                </Label>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field }) => (
                        <input
                            {...field}
                            aria-invalid={error ? "true" : "false"}
                            className="form-control"
                            placeholder={placeholder}
                            id="exampleInputEmail1"
                            type={type ? type : "text"}
                            maxLength={max}
                            value={field.value || ""}  // This ensures the input value is controlled by react-hook-form
                            onChange={(e) => field.onChange(e.target.value)} // Handles change event properly
                            disabled={disabled}
                            max={moment().format("YYYY-MM-DD")}
                        />
                    )}
                />
                {error && (
                    <p role="alert" className='commonNameErr'>
                        {error?.message}
                    </p>
                )}
            </FormGroup>
        </>
    );
};
// Define PropTypes
CustomInput.propTypes = {
    fieldName: PropTypes.string.isRequired,  // fieldName is required and should be a string
    control: PropTypes.object.isRequired,    // control is required and should be an object
    fieldLabel: PropTypes.string,            // fieldLabel is optional but should be a string
    placeholder: PropTypes.string,           // placeholder is optional and should be a string
    error: PropTypes.oneOfType([             // error can be a boolean or an object
        PropTypes.bool, 
        PropTypes.object
    ]),
    type: PropTypes.string,                  // type is optional and should be a string
    max: PropTypes.number,                   // max is optional and should be a number (for maxLength)
    disabled: PropTypes.bool,                // disabled is optional and should be a boolean
    inputGroupClasses: PropTypes.string      // inputGroupClasses is optional and should be a string
};

// Default Prop Values
CustomInput.defaultProps = {
    fieldLabel: '',        // Default to empty string if not provided
    placeholder: '',       // Default placeholder to empty string
    type: 'text',          // Default input type is 'text'
    disabled: false        // Default disabled is false
};

export default CustomInput;
