import React, { useState, useEffect } from 'react';
import AddUnit from './AddUnit';
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import AddUnitmodal from './AddUnitmodal';
import Editunitmodal from './Editunitmodal';
const jsonData = [
	{

		id: "",
		name: "",
		description: '',
		selling: '',
		regular: '',
		offer: ''
	},

];
function TableData({ variant, onAdd,setVariantsData,unitliststore, onDelete }) {
	const [open, setOpen] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
    const [editdata,seteditdata]=useState([])


	const onOpenModal = () => {
		setOpen(true);
	};

	const onCloseModal = () => {
		setOpen(false);
	};
	const onOpenModalEdit = () => {
		setOpenEdit(true);
	};

	const onCloseModalEdit = () => {
		setOpenEdit(false);
	};
	const deletedata = (id) => {
		onDelete(id)
	  	
	}

	const Editdata=(id)=>{
		const result =variant.find((item) => item.uid === id
	)

		seteditdata(result)
		if(editdata){
			onOpenModalEdit()

		}
		
		

	}

	const tableRows = variant?.map((info) => {
		return (
			<tr key={info.uid}>
				<td>{info.id.label}</td>
				<td>{info.name}</td>
				{/* <td>{info.description}</td> */}
				<td>{info.selling}</td>
				<td>{info.regular}</td>
				<td>{info.offer}</td>
				
				<td><span onClick={()=>Editdata(info.uid)}>

					<i
						className="fa fa-edit"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "blue",
							cursor: "pointer"
						}}
					></i>
				</span>
				<span onClick={()=>deletedata(info.uid)}>

					<i
						className="fa fa-trash"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: "#e4566e",
							cursor: "pointer"
						}}
					></i>
				</span></td>
			</tr>
		);
	});

	const addRows = (data) => {
		// const totalVariants = variantsData.length;
		// data.id = totalVariants;
		// const updatedVariantsData = [...variantsData];
		// updatedVariantsData.push(data);
		// setVariantsData(updatedVariantsData);
		onAdd(data)
	};



	return (
		<div>
			<div style={{ display: "flex", justifyContent: "flex-end", paddingTop: 10, paddingBottom: 10 }}>
				<Button onClick={onOpenModal}>Add Unit</Button>
			</div>
			<table className="table table-stripped">
				<thead>
					<tr>
						<th>Unit</th>
						<th>Variant Name</th>
						{/* <th>Variant Description</th> */}
						<th>Selling Price</th>
						<th>Maximum Retail Price</th>
						<th>Offer Price</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>{tableRows}</tbody>
			</table>


			{open && <AddUnitmodal openModal={open} onCloseModal={() => setOpen(false)} func={addRows}  unitlistmap={unitliststore}/>}
            {openEdit && <Editunitmodal openModal={openEdit} onCloseModal={() => setOpenEdit(false)} editdata={editdata} func={onAdd} variant={variant} setVariantsData={setVariantsData}/>}
		</div>
	);
}

export default TableData;
