import {
    GET_REPORTS_LIST_SUCCESS,
    GET_REPORT_LIST_FAIL,
    RESET_ERROR,
    GET_DOWNLOAD_DATA_SUCESS,
    GET_DOWNLOAD_DATA_FAIL,
    GET_REPORTS_SALES_FAIL,
    GET_REPORTS_SALES_SUCCESS,
    GET_REPORTS_SALES_DOWNLOAD_SUCCESS,
    GET_REPORTS_SALES_DOWNLOAD_FAIL
} from '../constants/reportsConstants';

export const ReportReducer = (state = { error: null }, action) => {
    switch (action.type) {
        case RESET_ERROR:
            return {
                ...state,
                error: null,

            };
        /**************************REPORTS**********************************/
        case GET_REPORTS_LIST_SUCCESS:
            return {
                ...state,
                reportsListdata: action.payload?.data, // List of reports for the current page
                totalCount: action.payload?.total, // Total number of reports for pagination
                error: null, // Reset error if success
            };
        case GET_REPORT_LIST_FAIL:
            return {
                ...state,
                error: action.payload, // Set error in case of failure
                reportsListdata: [], // Reset data if the request fails
                totalCount: 0, // Reset totalCount on failure
            };
        /**************************REPORTS DOWNLOAD**********************************/
        case GET_DOWNLOAD_DATA_SUCESS:
            return {
                ...state,
                reportsListdownlodedData: action.payload,
                error: null, // Reset error if success
            };
        case GET_DOWNLOAD_DATA_FAIL:
            return {
                ...state,
                error: action.payload, // Set error in case of failure
                reportsListdownlodedData: null, // Reset data if the request fails
                totalCount: 0, // Reset totalCount on failure
            };
        /**************************SALES REPORTS**********************************/
        case GET_REPORTS_SALES_SUCCESS:
            return {
                ...state,
                salesReportsData: action.payload,
                totalCount: action.payload?.orders?.total,
                error: null, // Reset error if success
            };
        case GET_REPORTS_SALES_FAIL:
            return {
                ...state,
                error: action.payload, // Set error in case of failure
                totalCount: 0,
                salesReportsData: [], // Reset data if the request fails
            };
        /**************************SALES DOWNLOAD REPORTS**********************************/
        case GET_REPORTS_SALES_DOWNLOAD_SUCCESS:
            return {
                ...state,
                salesReportsData: action.payload,
                error: null, // Reset error if success
            };
        case GET_REPORTS_SALES_DOWNLOAD_FAIL:
            return {
                ...state,
                error: action.payload, // Set error in case of failure
                salesReportsData: [], // Reset data if the request fails
            };
        default:
            return state;
    }
};
