import React, { Fragment, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Controller } from "react-hook-form";
import CustomInput from "../common/CustomInput";
import Reactotron from "reactotron-react-js"
import Spinner from "../common/Spinner";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";

const Customotp = ({emailMain}) => {

    Reactotron.log(emailMain,"aaad")

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    
    const schema = yup.object({
        otp: yup.string()
            .required()
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(6, 'Must be exactly 6 digits')
            .max(6, 'Must be exactly 6 digits'),

    }).required();

    const { register, formState: { errors }, handleSubmit, control } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            otp: ''
        }
    });

    // console.log({ errors })

    const onSubmitdata = async (data) => {

        const datas = {
            email: emailMain,
            otp: data?.otp
        }

        try {
			setLoading(true)
            const res = await customAxios.post('customer/forgot-customer-password-change', datas)
			if( res?.status === 200 || 201) {
				toast.success('OTP Verified')
                navigate('/auth/resetPassword', { state: { user_id: res?.data?.data } });
			}
        } catch (err) {
            toast.error(err.message)
        }
		finally {
            setLoading(false)
        }
    }

    
    return (
        <>
            <Form className="form-horizontal auth-form">
                <CustomInput
                    fieldName={"otp"}
                    placeholder="Type your OTP"
                    error={errors.otp}
                    control={control}
                    maxLength={"6"}
                />
                <div className="form-button pull-right">
                        {loading ? <Spinner />:  (<Button
                        color="primary"

                        onClick={handleSubmit(onSubmitdata)}>
                        Submit</Button>)}
                </div>
            </Form>
        </>
    )
}

export default Customotp