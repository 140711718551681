import React from 'react';
import { FormGroup, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import Select from 'react-select';
import PropTypes from 'prop-types';

const SelectInput = ({ fieldName, control, fieldLabel, error, max, isMultiple, onChangeValue, options }) => {
    return (
        <>
            <FormGroup>
                <Label
                    htmlFor="recipient-name"
                    className="col-form-label"
                >
                    {fieldLabel}
                </Label>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) =>
                        <Select
                            value={value}
                            onBlur={onBlur}
                            aria-invalid={error ? "true" : "false"}
                            maxLength={max}
                            isMulti={isMultiple}
                            options={options}
                            onChange={onChangeValue ? onChangeValue : onChange}
                        />
                    }
                />
                {error && <p role="alert" style={{ color: "red", display: "flex", flexDirection: "start", paddingLeft: "10px", fontSize: "12px" }}>{error?.message}</p>}
            </FormGroup>
        </>
    )
}
SelectInput.propTypes = {
    fieldName: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    fieldLabel: PropTypes.string,
    error: PropTypes.object,
    children: PropTypes.node,
    max: PropTypes.number,
    isMultiple: PropTypes.bool,
    selectvalue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChangeValue: PropTypes.func,
    options: PropTypes.array.isRequired
};
export default SelectInput